
<template>
  <div class="page-basic-info">
    <!-- 审核通过 -->
    <!-- <a-alert v-if="form.status == 1" class="success-alert" message="信息已通过审核,如信息有变,可重新提交进行修改~" type="info" show-icon>
      <img src="/success.png" alt="" slot="icon" class="info-icon" />
    </a-alert> -->
    <template v-if="form.status == 1"></template>
    <!-- 审核中 -->
    <a-alert v-else-if="form.status == 0" type="info" show-icon>
      <img src="/warning_icon.png" alt="" slot="icon" class="info-icon" />
      <div class="alert-area" slot="message">
        <span class="label">个人信息正在审核中，审核通过后即可发布采购需求。</span>
<!--        <a @click="handleCancel">撤销审核</a>-->
      </div>
    </a-alert>
    <!-- 审核失败 -->
    <a-alert v-else-if="form.status == -1" class="error-alert" type="info" show-icon>
      <img src="/error.png" alt="" slot="icon" class="info-icon" />
      <div slot="message">
        <span class="label">尊敬的采购商，您的企业认证信息被驳回，</span>
        <span class="underline">驳回理由：{{ form.rejectReason || '' }}，</span>
        <span class="label">请重新提交认证</span>
      </div>
    </a-alert>
    <!-- 待完善 -->
    <a-alert
      v-else
      :message="
        setUserInfo().tradeIdentity == '0'
          ? '根据平台要求：找采购商、发布采购需求，请先完成企业认证'
          : '根据平台要求：找供应商、发布采购需求，请先完成企业认证'
      "
      type="info"
      show-icon
    >
      <img src="/warning_icon.png" alt="" slot="icon" class="info-icon" />
    </a-alert>

    <!-- 表单 -->
    <a-form-model
      class="form-cover"
      ref="basicForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :colon="false"
      style="margin-top: 16px"
    >
      <a-row class="basicInfo-form-item">
        <a-col :span="24">
          <a-form-model-item
            label="姓名"
            prop="name"
            :rules="{ required: true, message: '请输入' }"
          >
            <a-input
              style="width: 320px; height: 38px"
              :disabled="disabled"
              placeholder="请输入"
              v-model="form.name"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item
            label="手机号"
            prop="phone"
            :rules="{ required: true, message: '请输入' }"
          >
            <a-input
              style="width: 320px; height: 38px"
              :disabled="disabled"
              placeholder="请输入"
              v-model="form.phone"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
        <a-row :gutter="26" class="basicInfo-form-item">
          <a-col :span="12">
          </a-col>
          <a-col :span="24" style="margin-left: 160px">
            <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
          </a-col>
        </a-row>
    </a-form-model>
  </div>
</template>

<script>
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import uploadFile from '@/components/plugins/uploadFile.vue'
import { postAction, getAction, postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import region from '@/util/region.json'

export default {
  components: {
    JDictSelect,
    uploadFile
  },
  data() {
    return {
      showPicker: false,
      loading: false,
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      leftInfo: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      labelCols: { span: 6 },
      wrapperCols: { span: 18 },
      isEdit: false,
      licensePictureList: [],
      form: {},
      additional: {},
      disabled: false,
      companyInfo: {},
      baseInfo: {},
      region
    }
  },
  created() {
    this.headers = localStorage.getItem('authInfo')
      ? { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
      : {}
    // this.disabled = true
    this.handleInitData()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    // 编辑采购计划
    handleEditPlan() {
      if (this.setUserInfo().tradeIdentity == '0') {
        this.$refs.SupplierEditPlanRef.handleEdit()
      } else {
        this.$refs.PurchaserEditPlanRef.handleEdit()
      }
    },
    // 自定义上传附件校验
    uploadFileChange(rule, value, callback) {
      if (this.licensePictureList.length === 0) {
        return callback('请上传营业执照')
      } else {
        return true
      }
    },
    validateEmail(rule, value, callback) {
      let reg = new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    },
    validatePhone(rule, value, callback) {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    },
    handleSetNewData(data) {
      this.form = Object.assign({}, data)
      this.form.region = [data.provinceCode, data.cityCode, data.areaCode]
    },
    /**
     * @description: 撤销
     * @return {*}
     */
    handleCancel() {
      getAction('/business/revoke/approval', {}).then((res) => {
        const { success, message } = res
        if (success) {
          this.handleInitData()
        } else {
          this.$message.error(message)
        }
      })
    },
    handleInitData() {
      if (!this.setUserInfo().businessId) return
      getAction('/v2/business/basic/info/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then((res) => {
        const { success, data } = res
        if (success) {
          this.handleSetNewData(data)
        }
      })
    },
    handleUpDateInfo(callback) {
      getAction('/user/info', { userId: this.setUserInfo().userId }).then((res) => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback && callback()
        }
      })
    },
    handleChooseAddress() {
      const { provinceName, cityName } = this.form
      let city = ''
      if (cityName) {
        city =
          provinceName == '北京市' || provinceName == '上海市' || provinceName == '重庆市' || provinceName == '天津市'
            ? provinceName
            : cityName
      }
      // this.$refs.MapPickerRef.onOpen(city)
    },
    /**
     * @description: 上传logo
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    handleChangeImage({ file, fileList }) {
      if (file.status === 'done') {
        if (file.response) {
          if (file.response.success) {
            const { data } = file.response
            this.form.picture = data.url
            if (this.form.status == 1) {
              this.handleSubmit()
            }
          }
        }
      }
      this.fileList = fileList.filter((i) => i.status != 'error')
    },
    /**
     * @description: 提交
     * @return {*}
     */
    handleSubmit() {
      const that = this
      this.$refs['basicForm'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            tradeIdentity: this.setUserInfo().tradeIdentity,
            businessId: this.setUserInfo().businessId,
            licensePicture: this.licensePictureList[0] ? this.licensePictureList[0]['url'] : '' // 营业执照
          }
          if (this.setUserInfo().tradeIdentity != '0') {
            const { providerArea, providerAreaRegion, purchasingMethod, packagingCostTotal } = this.form
            let capacity = this.form.capacity || {}
            capacity = {
              ...capacity,
              providerArea,
              providerAreaRegion,
              purchasingMethod,
              packagingCostTotal,
              userId: this.setUserInfo().userId
            }
            params.capacity = capacity
          }

          // 供应商
          this.loading = true
          postAction('/v2/business/basic/info/update', {
            ...params
          })
            .then((res) => {
              this.loading = false
              const { success, message } = res
              if (success) {
                this.$message.success('保存成功')
                if (!this.setUserInfo().businessId) {
                  this.handleUpDateInfo(() => that.handleInitData()) // 没有企业ID添加刷新
                } else {
                  this.handleInitData()
                }
              } else {
                this.$message.error(message)
              }
            })
            .catch((e) => {
              this.loading = false
            })
          // } else {
          //   this.$emit('nextStep', {
          //     status: 0,
          //     params,
          //   })
          // }
        }
      })
    },
    handleInitPlan() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then((res) => {
        const { success, data } = res
        if (success) {
          this.baseInfo = data
          const { supplierPurchasePlan, capacity } = data
          if (supplierPurchasePlan || capacity) {
            // 处理显示保存问题
            this.companyInfo = supplierPurchasePlan || capacity
            const { providerArea, providerAreaRegion, purchasingMethod, packagingCostTotal } = this.companyInfo
            this.form = { ...this.form, providerArea, providerAreaRegion, purchasingMethod, packagingCostTotal }
          }
        }
      })
    },
    /**
     * @description: 更新code
     * @param {*} e
     * @return {*}
     */
    handleChangeAddressCode({ nameArr, codeArr }) {
      const [provinceName, cityName, areaName] = nameArr
      const [provinceCode, cityCode, areaCode] = codeArr

      this.form.provinceName = provinceName || null // 省
      this.form.cityName = cityName || null
      this.form.areaName = areaName || null

      this.form.provinceCode = provinceCode || null // 省
      this.form.cityCode = cityCode || null
      this.form.areaCode = areaCode || null
      this.$set(this.form, 'position', undefined)
      this.$set(this.form, 'address', undefined)
      this.$nextTick(() => {
        this.handleChooseAddress()
      })
    },
    handleGetPic(e) {
      this.$forceUpdate()
      if (e.length) {
        this.form.licensePicture = e[0].url
      } else {
        this.licensePictureList = []
      }
    },
    handleEditCompanyUser() {
      this.$refs.moreInfoRef.handleEdit()
    },
    handleEditDesc() {
      this.$refs.DescEditorRef.handleEdit()
    },
    handleEditAddress() {
      this.$refs.AddressEditorRef.handleEdit()
    },
    handleReload() {
      this.handleInitData()
    },
    onRegionChange(value, selectedOptions) {
      this.form.provinceName = selectedOptions[0].label
      this.form.cityName = selectedOptions[1].label
      this.form.areaName = selectedOptions[2].label

      this.form.provinceCode = selectedOptions[0].value
      this.form.cityCode = selectedOptions[1].value
      this.form.areaCode = selectedOptions[2].value
    }
  }
}
</script>

<style lang="less" scoped>
.page-basic-info {
  width: 100%;

  ::v-deep .ant-alert-info {
    border: none;
    background: rgba(255, 125, 47, 0.08);
    border-radius: 4px;
    height: 40px;

    &.success-alert {
      background: #e6faf3;
    }

    &.error-alert {
      background: #ee42611a;
    }

    .info-icon {
      width: 17px;
      margin-top: -9px;
    }

    .ant-alert-message {
      color: #131212;
    }
  }

  .alert-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .underline {
    text-decoration: underline;
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #ff6e2d;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .cover {
      display: flex;
      align-items: center;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .sub-title {
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 400;
      color: #00000073;
      margin-left: 2px;
    }

    .action {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      color: #ff6026;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;

      img.icon {
        width: 16px;
        margin-right: 4px;
      }
    }
  }

  .upload-btn {
    padding-left: 0;
  }

  img.cover-img {
    width: 186px;
    height: 186px;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid #efefef;
  }

  ::v-deep .ant-select-selection {
    height: 38px;
  }

  ::v-deep .ant-select-selection__rendered {
    line-height: 38px;
  }

  .text-area-box {
    width: 599px;
    position: relative;

    .counts {
      position: absolute;
      line-height: 14px;
      color: #00000073;
      right: 6px;
      bottom: 10px;
    }

    .option-btn {
      position: absolute;
      bottom: -5px;
      right: -68px;
    }
  }
}

.submit-btn {
  margin-top: 36px;
  margin-bottom: 24px;
  text-align: center;

  ::v-deep .ant-btn {
    width: 112px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
  }
}

.address-btn {
  position: absolute;
  right: -65px;
  bottom: -20px;
}

.noPic {
  width: 80px;
  height: 80px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }
}

.ant-form-item {
  ::v-deep .ant-col-3 {
    width: 13.7%;
  }

  ::v-deep .ant-col-1 {
    width: 6.7%;
  }

  ::v-deep .ant-col-6 {
    width: 20%;
  }
}
</style>
